import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
//import { TimerObservable } from 'rxjs/observable/TimerObservable';
import { Subscription } from 'rxjs';

import { environment } from './../../../environments/environment';
import { AuthenticationService } from './../../core/services/auth.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { AuthGuard } from 'src/app/core/guards/auth.guard';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {

    private _mobileQueryListener: () => void;
    mobileQuery: MediaQueryList;
    showSpinner: boolean;
    userName: string;
    isAdmin: boolean;
    rol = '';

    menu = {
        'root': [{
            label: 'Dashboard',
            icon: 'dashboard',
            route: '/dashboard'
        },
        {
            label: 'Mapa',
            icon: 'map',
            route: '/mapa'
        },
        {
            label: 'Diagrama funcional',
            icon: 'account_tree',
            route: '/diagrama-funcional'
        },
        {
            label: 'Estado flujómetros',
            icon: 'sensors',
            route: '/flujometro/estado'
        },
        {
            label: 'Reportes',
            icon: 'leaderboard',
            route: '/reportes'
        },],
        'administrador': [{
            label: 'Dashboard',
            icon: 'dashboard',
            route: '/dashboard'
        },
        {
            label: 'Mapa',
            icon: 'map',
            route: '/mapa'
        },
        {
            label: 'Diagrama funcional',
            icon: 'account_tree',
            route: '/diagrama-funcional'
        },
        {
            label: 'Estado flujómetros',
            icon: 'sensors',
            route: '/flujometro/estado'
        },
        {
            label: 'Reportes',
            icon: 'leaderboard',
            route: '/reportes'
        },],
        'usuario': [{
            label: 'Dashboard',
            icon: 'dashboard',
            route: '/dashboard'
        },
        {
            label: 'Mapa',
            icon: 'map',
            route: '/mapa'
        },
        {
            label: 'Diagrama funcional',
            icon: 'account_tree',
            route: '/diagrama-funcional'
        },
        {
            label: 'Estado flujómetros',
            icon: 'sensors',
            route: '/flujometro/estado'
        },
        {
            label: 'Reportes',
            icon: 'leaderboard',
            route: '/reportes'
        },],
    }

    


    private autoLogoutSubscription: Subscription;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher,
        public spinnerService: SpinnerService,
        private authService: AuthenticationService,
        private authGuard: AuthGuard) {

        this.mobileQuery = this.media.matchMedia('(max-width: 1000px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    logOut() {
        this.authService.logout();
    }

    ngOnInit(): void {
        const user = this.authService.getCurrentUser();
        this.rol = user.roles[0].name.toLowerCase();

        if (this.rol == 'usuario-pelambre-mina') {
            this.rol = 'usuario';
        }

        this.userName = user.username;

        // Auto log-out subscription
        /*const timer = TimerObservable.create(2000, 5000);
        this.autoLogoutSubscription = timer.subscribe(t => {
            this.authGuard.canActivate();
        });*/
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
        //this.autoLogoutSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    onCloseMenu(snav): void {
        if (this.mobileQuery.matches) {
            snav.toggle();
        }
    }
}
