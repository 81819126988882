<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
  <pre>
  <p *ngIf="json !==''">{{json | json}}</p>
</pre>
</div>

<div mat-dialog-actions>
  <button mat-button color="primary" (click)="onDismiss()">Salir</button>
  <!--<button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>-->
</div>