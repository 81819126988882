<h1 mat-dialog-title *ngIf='loadStatus'>
    {{statusDevice.name}}
  </h1>
  
  <div mat-dialog-content *ngIf='!loadStatus' fxLayoutAlign='center center' fxLayout='column' style="margin-top: 15px;">
    <mat-spinner></mat-spinner>
    <p>Cargando información...</p>
  </div>

  <div mat-dialog-content *ngIf='loadStatus' fxLayout='column' style="margin-top: 15px;">
    <p><b>Temperatura:</b> {{(statusDevice.temperature == null) ? 'Sin lectura' : (statusDevice.temperature.value | number:'1.0-2') + ' °C'}}</p>
    <p><b>Presión:</b> {{(statusDevice.pressure == null) ? 'Sin lectura' : (statusDevice.pressure.value | number:'1.0-2') + ' [bar]'}}</p>
    <p><b>Status:</b> {{(statusDevice.status == null) ? 'Sin lectura' : statusDevice.status }}</p>
    <p><b>Flujo totalizado:</b> {{(statusDevice.flow == null) ? 'Sin lectura' : (statusDevice.flow.value  | number:'1.0-2') + ' [m3]' }}</p>
    <p><b>Flujo instantáneo:</b> {{(statusDevice.instantFlow == null) ? 'Sin lectura' : ((statusDevice.instantFlow.value/3.6)  | number:'1.0-2') + ' [l/s]' }}</p>
  </div>
  
  <div mat-dialog-actions fxLayoutAlign='end none' style="margin-top: 10px;">
    <button mat-button (click)="goToDetail()">Ir al detalle</button>
    <button mat-raised-button color="primary" (click)="onDismiss()">Aceptar</button>
  </div>
