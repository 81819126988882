import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-CL';


registerLocaleData(localeEs, 'es-CL');


@NgModule({
  declarations: [
    AppComponent,
  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: 'es-CL' // 'de' for Germany, 'fr' for France ...
  }],

  imports: [
    BrowserModule,
    
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    CustomMaterialModule.forRoot(),
    AppRoutingModule,        
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
