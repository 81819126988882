import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './core/guards/admin.guard';

import { AuthGuard } from './core/guards/auth.guard';
import { LoginGuard } from './core/guards/login.guard';
import { UserGuard } from './core/guards/user.guard';
import { LayoutComponent } from './shared/layout/layout.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';


const appRoutes: Routes = [
    /*{
        path: 'auth',
        loadChildren: './features/auth/auth.module#AuthModule'
    },*/

    //Login
    {
        path: '',
        loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
        canActivate: [LoginGuard]
    },

    //Error 404
    {
        path: 'not-found',
        component: NotFoundComponent
    },

    //Admin
    {        
        path: 'administrador',
        component: LayoutComponent, // this is the component with the <router-outlet> in the template
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [AuthGuard, AdminGuard]
            },
            {
                path: 'mapa',
                loadChildren: () => import('./features/map/map.module').then(m => m.MapModule),
                canActivate: [AuthGuard, AdminGuard]
            },
            {
                path: 'flujometro',
                loadChildren: () => import('./features/flowmeter/flowmeter.module').then(m => m.FlowmeterModule),
                canActivate: [AuthGuard, AdminGuard]
            },
            {
                path: 'diagrama-funcional',
                loadChildren: () => import('./features/functionalDiagram/functionalDiagram.module').then(m => m.FunctionalDiagramModule),
                canActivate: [AuthGuard, AdminGuard]
            },
            {
                path: 'reportes',
                loadChildren: () => import('./features/report/report.module').then(m => m.ReportModule),
                canActivate: [AuthGuard, AdminGuard]
            },
            {
                path: 'account',
                loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule),
                canActivate: [AuthGuard, AdminGuard]
            },
        ],
        canActivate: [AuthGuard, AdminGuard]
    },

    {
        path: 'root',
        component: LayoutComponent, // this is the component with the <router-outlet> in the template
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [AuthGuard, AdminGuard]
            },
            {
                path: 'mapa',
                loadChildren: () => import('./features/map/map.module').then(m => m.MapModule),
                canActivate: [AuthGuard, AdminGuard]
            },
            {
                path: 'flujometro',
                loadChildren: () => import('./features/flowmeter/flowmeter.module').then(m => m.FlowmeterModule),
                canActivate: [AuthGuard, AdminGuard]
            },
            {
                path: 'diagrama-funcional',
                loadChildren: () => import('./features/functionalDiagram/functionalDiagram.module').then(m => m.FunctionalDiagramModule),
                canActivate: [AuthGuard, AdminGuard]
            },
            {
                path: 'reportes',
                loadChildren: () => import('./features/report/report.module').then(m => m.ReportModule),
                canActivate: [AuthGuard, AdminGuard]
            },
            {
                path: 'account',
                loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule),
                canActivate: [AuthGuard, AdminGuard]
            },
        ],
        canActivate: [AuthGuard, AdminGuard]
    },

    //Usuario
    {
        path: 'usuario',
        component: LayoutComponent, // this is the component with the <router-outlet> in the template
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [AuthGuard, UserGuard]
            },
            {
                path: 'mapa',
                loadChildren: () => import('./features/map/map.module').then(m => m.MapModule),
                canActivate: [AuthGuard, UserGuard]
            },
            {
                path: 'flujometro',
                loadChildren: () => import('./features/flowmeter/flowmeter.module').then(m => m.FlowmeterModule),
                canActivate: [AuthGuard, UserGuard]
            },
            {
                path: 'diagrama-funcional',
                loadChildren: () => import('./features/functionalDiagram/functionalDiagram.module').then(m => m.FunctionalDiagramModule),
                canActivate: [AuthGuard, UserGuard]
            },
            {
                path: 'reportes',
                loadChildren: () => import('./features/report/report.module').then(m => m.ReportModule),
                canActivate: [AuthGuard, UserGuard]
            },
            {
                path: 'account',
                loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule),
                canActivate: [AuthGuard, UserGuard]
            },
        ],
        canActivate: [AuthGuard, UserGuard]
    },
    
    {
        path: '**',
        redirectTo: 'not-found',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule { }
