import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import * as moment from 'moment';

import { AuthenticationService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private router: Router,
    private notificationService: NotificationService,
    private authService: AuthenticationService) { }

  canActivate() {
    const user = this.authService.getCurrentUser();
    const logged = this.authService.isLoggedIn();


    if (user && logged) {
      this.router.navigate(['/administrador/dashboard']);
      return false;      
    }

    this.authService.logout();
    return true;
  }

}
