import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import * as moment from 'moment';

import { AuthenticationService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(private router: Router,
    private authService: AuthenticationService) { }

    canActivate() {
      const user = this.authService.getCurrentUser();
      if (user != null) {
          if (user && (user.roles[0].name.toLowerCase() === 'usuario-pelambre-mina')) {
              return true;
          } else {
              this.router.navigate(['/']);
              return false;
          }
      } else {
          this.router.navigate(['/']);
          return false;
      }

  }

}
