import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import * as moment from 'moment';

import { AuthenticationService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
        private notificationService: NotificationService,
        private authService: AuthenticationService) { }

    canActivate() {
        const user = this.authService.getCurrentUser();
        //console.log(moment(user.exp));
        /*if (user && user.exp) {
            if (moment() < moment(user.exp)) {
                return true;
            } else {
                this.notificationService.openSnackBar('Your session has expired');
                this.router.navigate(['']);
                return false;
            }
        }*/

        //console.log('user', user)
        if (user) {
            return true;
        }

        this.router.navigate(['/']);
        return false;
    }
}
