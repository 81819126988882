import { Injectable, Inject } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import 'rxjs/add/operator/delay';

import { environment } from '../../../environments/environment';
import { of, EMPTY } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends ApiService{

    constructor(http: HttpClient,
        @Inject('LOCALSTORAGE') public localStorage: Storage) {
            super(`${environment.apiUrl}`, http);
    }

    login(email: string, password: string) {
        //console.log('logeando');    
        //console.log(this.getCurrentUser())    
        const formData = {
            username: email,
            password: password
        }
        //console.log('logeando 2');
        return this.postUrl('/auth/login', JSON.stringify(formData));
    }

    logout(): void {
        // clear token remove user from local storage to log user out
        this.localStorage.removeItem('tokenVogt');
        //this.getUrl('/auth/logout');
    }

    getCurrentUser(): any {
        // TODO: Enable after implementation
        // return JSON.parse(this.localStorage.getItem('currentUser'));
        //return JSON.parse(this.localStorage.getItem('currentUser'));
        if (this.localStorage.getItem('tokenVogt') !== null) {
            return jwt_decode(this.localStorage.getItem('tokenVogt'));
        } else {
            return null;
        }
        
    }

    public isLoggedIn() {
        return moment().isBefore(this.getExpiration());
    }

    getToken(){
        return this.localStorage.getItem('tokenVogt');
    }

    getExpiration() {
        const expiration = localStorage.getItem("expires_at");
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    } 

    passwordResetRequest(email: string) {
        return of(true).delay(1000);
    }

    changePassword(email: string, currentPwd: string, newPwd: string) {
        return of(true).delay(1000);
    }

    passwordReset(email: string, token: string, password: string, confirmPassword: string): any {
        return of(true).delay(1000);
    }
}
