import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { StatusService } from 'src/app/core/services/status.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-flowmeter-detail-dialog',
  templateUrl: './flowmeter-detail-dialog.component.html',
  styleUrls: ['./flowmeter-detail-dialog.component.css']
})
export class FlowmeterDetailDialogComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription;
  title: string;
  json: string;
  loadStatus = false;
  statusDevice = null;
  rol = '';

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    public statusService: StatusService,
    public dialogRef: MatDialogRef<FlowmeterDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogActionsModel) {
    this.title = data.title;
    this.json = data.json;
  }

  ngOnInit() {
    const user = this.authService.getCurrentUser();

    this.rol = user.roles[0].name.toLowerCase();
    this.loadStatus = false;
    this.subscription.add(
      this.statusService.getStatusDeviceDetail(this.json['id']).subscribe(
        response => {
          //console.log(response);
          this.statusDevice = response;
          this.loadStatus = true;
        }, error => {
          //console.log(error);
        }
      )
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  goToDetail() {
    this.dialogRef.close(false);
    this.router.navigate(['/'+this.rol+'/flujometro/detalle/' + this.json['id']]);
  }

}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
 export class ConfirmDialogActionsModel {

  constructor(public title: string, public json: string) {
  }
}
