import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatusService extends ApiService {

  constructor(http: HttpClient) {
    super(`${environment.apiUrl}`, http);
  }

  getStatusDevices() {
    return this.getUrl('/status');
  }

  getStatusDeviceDetail(id) {
    return this.getUrl('/status/' + id);
  }
}
