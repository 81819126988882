<div class="navbar-container" [class.example-is-mobile]="mobileQuery.matches">

  <!-- Top navbar -->
  <mat-toolbar class="navbar" color="primary">

    <button style="margin-right: 5px;" [ngStyle]="{display: mobileQuery.matches ? 'block' :'none' }" mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <a class="navbar-brand" [routerLink]="['/'+rol+'/dashboard']" matTooltip="Dashboard">
      <img src="../../../assets/logo/logo_blanco.png" style="height: 40px; margin-top: 5px;" />
    </a>

    <span class="navbar-spacer"></span>
    <button mat-button [matMenuTriggerFor]="userMenu">
      <mat-icon>person</mat-icon>
      <span fxShow>
        {{userName}}
      </span>
    </button>
    <mat-menu #userMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">

      <a mat-menu-item [routerLink]="['/'+rol+'/account/profile']">
        <mat-icon>person</mat-icon>
        <span>Perfil</span>
      </a>

      <a mat-menu-item [routerLink]="['']" (click)='logOut()'>
        <mat-icon>exit_to_app</mat-icon>
        <span>Cerrar sesión</span>
      </a>

    </mat-menu>

  </mat-toolbar>

  <mat-sidenav-container class="navbar-sidenav-container">
    <!-- Side nav -->
    <mat-sidenav #snav [opened]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" class="sidenav">

      <mat-nav-list>

        <mat-accordion class="menu-sidebar">

          <a mat-list-item *ngFor='let item of menu[rol]' (click)='onCloseMenu(snav);' [routerLink]="['/'+rol+item.route]" routerLinkActive="active">
            <mat-icon mat-list-icon class="icon-menu-only" routerLinkActive="active">
              {{item.icon}}
            </mat-icon>
            <p class="title-menu-only" routerLinkActive="active"> {{item.label}} </p>
          </a>


          <!--<mat-divider></mat-divider>

          <h4 mat-subheader>Periodo datos</h4>
          <mat-slide-toggle [(ngModel)]="timePeriod" (change)='onChange($event)' style="margin-left: 10px;">{{(timePeriod) ? 'Mensual' : 'Semanal'}}</mat-slide-toggle>-->



        </mat-accordion>

      </mat-nav-list>      

    </mat-sidenav>

    <!-- Main content -->
    <mat-sidenav-content class="sidenav-content" id="sidenav-container" >

      <router-outlet></router-outlet>

    </mat-sidenav-content>
  </mat-sidenav-container>

</div>