import { Injectable, Inject, } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { BadInput } from '../_errors/bad-input';
import { NotFoundError } from '../_errors/not-found-error';
import { ForbiddenError } from '../_errors/forbidden-error';
import { InternalServerError } from '../_errors/internal-server-error';
import { AppError } from '../_errors/app-error';
import { UnauthorizedError } from '../_errors/unauthorized-error';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiService {

  constructor(
    @Inject('') private url: string,
    private http: HttpClient,
  ) { }

  getAll(): Observable<any> {
    const tempUrl = this.url;

    return this.http.get(tempUrl)
      .pipe(catchError(this.handleError));
  }

  getUrl(url: string): Observable<any> {
    const tempUrl = this.url;

    return this.http.get(tempUrl + url)
      .pipe(catchError(this.handleError));
  }

  getUrlDownload(url: string): Observable<any> {
    
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    const tempUrl = this.url;

    return this.http.get(tempUrl + url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getWithParams(url: string, params: any): Observable<any> {
    const tempUrl = this.url;
    return this.http.get(tempUrl + url, { params })
      .pipe(catchError(this.handleError));
  }

  postUrl(url: string, resource: any): Observable<any> {
    const tempUrl = this.url;
    
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    }
    //console.log(tempUrl + url);
    return this.http.post(tempUrl + url, resource, options)
      .pipe(catchError(this.handleError));
  }

  putUrl(url: string, resource: any): Observable<any> {
    const tempUrl = this.url;

    return this.http.put(tempUrl + url, resource)
      .pipe(catchError(this.handleError));
  }

  create(resource): Observable<any> {
    const tempUrl = this.url;

    return this.http.post(tempUrl, resource)
      .pipe(catchError(this.handleError));
  }

  update(resource): Observable<any> {
    const tempUrl = this.url;

    resource.append('_method', 'PUT');
    return this.http.post(tempUrl + '/' + resource.get('id'), resource)
      .pipe(catchError(this.handleError));
  }

  delete(id): Observable<any> {
    const tempUrl = this.url;

    return this.http.delete(tempUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }
  deleteAll(): Observable<any> {
    const tempUrl = this.url;

    return this.http.delete(tempUrl)
      .pipe(catchError(this.handleError));
  }

  deleteUrl(url: string): Observable<any> {
    const tempUrl = this.url;

    return this.http.delete(tempUrl + url)
      .pipe(catchError(this.handleError));
  }

  setUrl(url: string) {
    this.url = url;
  }

  private handleError(error: Response) {
    if (error !== undefined) {
      if (error.status === 400) {
        return observableThrowError(new BadInput(error.json()));
      }
      if (error.status === 401) {
        return observableThrowError(new UnauthorizedError());
      }

      if (error.status === 403) {
        return observableThrowError(new ForbiddenError());
      }

      if (error.status === 404) {
        return observableThrowError(new NotFoundError());
      }

      if (error.status === 500) {
        return observableThrowError(new InternalServerError());
      }
    }
    return observableThrowError(new AppError(error));
  }
}
