import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(private router: Router,
        private authService: AuthenticationService) { }

    canActivate() {
        const user = this.authService.getCurrentUser();
        if (user != null) {
            if (user && (user.roles[0].name.toLowerCase() === 'root' || user.roles[0].name.toLowerCase() === 'administrador')) {
                return true;
            } else {
                this.router.navigate(['/']);
                return false;
            }
        } else {
            this.router.navigate(['/']);
            return false;
        }

    }
}
